import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {colors, below} from '../../utilities';
import ServicesMenu from '../../layout/ServicesMenu';
import ServiciosWebDevelopment from '../../layout/ServiciosWebDevelopment';
import ServiciosGoogleAds from '../../layout/ServiciosGoogleAds';
import ServiciosSocialMedia from '../../layout/ServiciosSocialMedia';
import ServiciosAnalytics from '../../layout/ServiciosAnalytics';
import ServiciosSeo from '../../layout/ServiciosSeo';
import {SectionsContainer} from '../../elements/Container';


const ServiciosBase = ({className}) => {
    
    let {url} = useRouteMatch();

    return (
        <SectionsContainer className={className}>
                <h2 className="servicios">Servicios</h2>
            <ServicesMenu/>
            <div className="servicios_list">
            <Switch>
              <Route exact path={`${url}/web_development`} component={ServiciosWebDevelopment}/>
              <Route exact path={`${url}/google_ads`} component={ServiciosGoogleAds}/>
              <Route exact path={`${url}/social_media`} component={ServiciosSocialMedia}/>
              <Route exact path={`${url}/analytics`} component={ServiciosAnalytics}/>
              <Route exact path={`${url}/posicionamiento_seo`} component={ServiciosSeo}/>
            </Switch>
            </div>
        </SectionsContainer>
    );
};

const Servicios = styled(ServiciosBase)`

    .servicios{
        font-size: 3rem;
        text-align:center;
        font-weight: 700;
        letter-spacing: .4rem;
        text-shadow: 4px 4px 15px ${colors.txt_1};
        color: ${colors.txt_2};  
        margin: 0.5rem auto;
    }

    .servicios_list{
        position: relative;
        margin: auto;
        width: 75%;
        display: flex;   
    }

    ${below.lg`

        .servicios{
            font-size: 2rem;
        }

        .servicios_list{
            width: 95%;
        }
    `};

    ${below.md`
        margin-top: 2.5rem;

        .servicios{
            font-size: 1.5rem;
        }

    `};

    ${below.xsm`
        
        .servicios{
            font-size: 1.2rem;
        }

    `};

`

export default Servicios; 
