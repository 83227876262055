import React from 'react';
import styled from 'styled-components';
import {SectionsContainer} from '../../elements/Container';
import { Whats, Phone, Emaail, Face, Insta, Ytb, Twttr } from '../../elements/Icons';
import Many from '../../elements/svg/Many.jsx';
import {setFlex, colors, demotion, below, elevation} from '../../utilities';

const ContactoBase = ({className}) => {
    return (            
        
        <SectionsContainer className={className}>
        <h2 className="contacto">Contacto</h2>
        <div className="box_container">
            <div className="box">
                <p className="box_title">Llámanos o Escríbenos</p>
                <a target="blank" href="https://api.whatsapp.com/send?phone=525559099044&amp;text=Hola,%20me%20contacto%20por%20la%20renta%20de%20juegos." className="contact_call_writte">
                <Whats size={25}/><span>55-5909-9044</span></a>
                <a target="blank" href="tel:5568323170" className="contact_call_writte"><Phone size={25}/><span>55-6832-3170</span></a>
                <a target="blank" href="https://mail.google.com/" className="contact_call_writte"><Emaail size={25}/><span>fperez@alfamarketingdigital.com</span></a>
            </div>
            <div className="box">
            <p className="box_title">Síguenos</p>
                <a target="blank" href="https://www.facebook.com/alfamarketingdigitalmexico"><Face  size="70"/></a>
                <a target="blank" href="https://www.instagram.com/alfa_marketing_digital_mexico/"><Insta size="70"/></a>
                <a target="blank" href="https://www.youtube.com/"><Ytb size="70"/></a>
                <a target="blank" href="https://twitter.com/home"><Twttr size="70"/></a>                
            </div>
        </div>
        </SectionsContainer>
        
    );
};

const Contacto = styled(ContactoBase)`    
  color: ${colors.txt_2};
  width: 95%;
  height: 75vh;  
  margin: 0.5rem auto;
  border-radius: .7rem;
  position: relative;  
  
  .contacto{      
    font-size: 3rem;
    text-align:center;
    font-weight: 700;
    letter-spacing: .4rem;
    text-shadow: 4px 4px 15px ${colors.txt_1};
    color: ${colors.txt_2};  
    margin: 1rem auto;
}

  .box_container{
    ${setFlex({y:"stretch"})};
    padding: 0.5rem;
    text-align: center;    
    overflow: auto;
      
      .box{
        width: 40%;
        margin: 1rem 1rem;
        border-radius: .8rem;
        padding: 1rem;
        ${elevation[4]};        
      
          .box_title{                
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
          }

          .contact_call_writte{      
            margin: 1.5rem 1.5rem;        
            font-size: 1.5rem;
            text-align: center;
            color: ${colors.txt_2};
            display: block;
          }
      }
  }

    ${below.lg`           
      .box_container{
        padding: .5rem;        
        
        .box{
          margin: 0 auto;            
        }

      }     
    `};

    ${below.md`      
      ${demotion[4]};
      margin: 3rem auto;      

      .contacto{      
        font-size: 1.5rem;      
      }

      .box_container{
        flex-flow: column nowrap;
        padding: 0rem;
        
        .box{
          width: 90%;
          margin: 1.5rem auto;          
        }

        .box_title{
            font-size: 1.2rem;
        }

        .contact_call_writte{
            transform: scale(.8);
        }
      }
    `}

    
`;


export default Contacto;