import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import {Wrapper} from './elements/Container';
import Slider from './layout/Slider';
import TopBar from './layout/TopBar';
import NavBar from './layout/Navbar';
import Footer from './layout/Footer';
import Routes from './components/routing/Routes';


function App() {
  return (
    <Wrapper>
      <TopBar/>
      <NavBar/>
      <Switch>
          <Route exact path='/' component={Slider} />
          <Routes/>
      </Switch>        

      <Footer/>
      <GlobalStyle/>
    </Wrapper>  
  );
}

export default App;
