import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Face extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlspace="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g><path fill={color} d="M586.2,351.3V234.7c0-39.8,37.8-55.8,56.8-55.8c45.1,0,116.5,0,116.5,0V10.6L584.1,10c-178.2,0-214.6,130.5-214.6,214.1l-1.4,127.3l-130.2,1.4v168.4h130.2c0,270.1,0,468.9,0,468.9h218c0,0,0-240.3,0-468.9l150.2,1.3l25.7-171.1L586.2,351.3L586.2,351.3z"/></g>
            </svg>        
        )
    }
}

Face.propTypes = {
    color: string,
    size: number
};


Face.defaultProps = {
    color: '#000',
    size:20
}