import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Google extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlspace="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g><path fill={color} d="M500,10C229.4,10,10,229.4,10,500s219.4,490,490,490s490-219.4,490-490S770.6,10,500,10z M507.3,867.5c-203.1,0-367.5-164.4-367.5-367.5s164.4-367.5,367.5-367.5c99.1,0,182.2,36.2,246.1,96.1l-99.7,96.1c-27.4-26.2-75-56.7-146.4-56.7C381.7,268,279.5,372,279.5,500s102.4,232,227.8,232c145.5,0,200-104.5,208.4-158.5H507.3V447.6h347c3.1,18.4,5.7,36.7,5.7,60.9C860.2,718.4,719.5,867.5,507.3,867.5L507.3,867.5z"/></g>
            </svg>        
        )
    }
}

Google.propTypes = {
    color: string,
    size: number
};


Google.defaultProps = {
    color: '#000',
    size:20
}