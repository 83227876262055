import React from 'react';
import styled from 'styled-components';
import { colors, setFlex, absolute, below } from '../utilities';


const ServicioGoogleAdsBase = ({className}) => {
    return (
        <div className={className}>            
                <div className="watermark">
                    <img className="icon" alt="" src="https://i.postimg.cc/T32Z5cDT/google.png"/>
                </div>
                <div className="info">
                    <p className="txt_google_ads">Ya tienes un sitio web espectacular, ahora lo que necesitas es gritárselo al mundo, hacer que todas aquellas personas que buscan eso
                    que tu ofreces te encuentren. La publicidad de boca en boca es buena, pero aceptémoslo, así no vas a logar los objetivos que esperas. </p>
                    <p className="txt_google_ads">Ya sea que tengas experiencia en GoogleAds o bien que quieras iniciarte en este mundo, te ayudamos y trabajamos a tu lado
                    para que juntos podamos crear las campañas más efectivas y enfocadas justo al nicho de mercado que quieres impactar para de esta manera poder obtener los mejores 
                    resultados al menor costo posible.</p>
                    <p className="txt_google_ads">Nuestras campañas no buscan obtener el mayor número de clicks, sino la mayor efectividad posible en esos clicks y al menor costo.
                    Para esto analizamos el mercado en que te desenvuelves y la competencia que enfrentas para poder orientar mejor tus anuncios y las palabras de busqueda que los activarán.</p>
                    <p className="txt_google_ads">Nuestro principal objetivo es que tengas los resultados que esperas en un tiempo razonable. Entendemos que nuestro éxito siempre irá de la mano
                    con tu éxito, así es que no dudes en que siempre estaremos atentos y dispuestos a hacer lo necesario para que lo consigas.</p>
                    <p className="txt_google_ads">Te orientamos para que puedas elegir entre campañas de búsqueda, display, remarketing o video y así armar la mejor combinación que lleve a tu
                    negocio un valor agregado real y tangible.</p>
                </div>            

        </div>
    );
};

const ServicioGoogleAds = styled(ServicioGoogleAdsBase)`    
    width: 100%;
    margin: .2rem auto;
    padding: .2rem .5rem;
    
    .info{        
        margin: 0 auto;
        padding: .4rem;        
        border-radius: .8rem;
        overflow: auto;

        .txt_google_ads{        
          color: ${colors.txt_2};
          font-size: 1.1rem;
          list-style-type: none;
          text-align: justify;
          padding: .3rem;
        }
    }

    .watermark{        
        ${absolute({x:"auto", y:"20%"})};
        ${setFlex()};
        width: 95%;
        padding: .5rem;
        opacity: 0.07;

        img{
          height: 30vh;
          width: auto;
        }        
    }


    ${below.md`      

        .info{                      
          margin: 0 auto;
          padding: .5rem;
          width: 100%;

          .txt_google_ads{        
            font-size: 1.1rem;
            list-style-type: none;
            padding: .2rem;
          }
        }


    `};

    ${below.sm`

        .watermark{
            margin-top: 6rem;
            margin-left: 0.5rem;
        }

        .icon{
            width: 80%;
        }

    `};

`

export default ServicioGoogleAds;