import React from 'react';
import styled from 'styled-components';
import { colors, setFlex, absolute, below } from '../utilities';


const ServicioWebDevelopmentBase = ({className}) => {
    return (
        <div className={className}>            
                <div className="watermark">
                    <img className="icon" alt="" src="https://i.postimg.cc/pd758phd/dcd6a3da3488ef99aac46fe0ef41449b.png"/>
                </div>
                <div className="info">
                    <p className="txt_web_development">El primer paso para una campaña exitosa online es tener un sitio web que sirva como tu mejor carta de presentación. Te ayudamos 
                    a conseguir la mejor presencia en el mundo digital adecuando el sitio a tu imagen corporativa teniendo siempre en mente el mensaje que quieres hacer llegar a tu nicho 
                    de mercado.</p>
                    <p className="txt_web_development">Desarrollamos sitios web eficientes, intuitivos y que capten la atención del usuario. Pueden ser sitios planos o bien con muchos
                    componentes que interactuen entre ellos. Ofrecemos la mejor arquitectura y funcionalidad para que sea tu mejor herramienta de comunicación hacia todos esos clientes
                    potenciales que esperas alcanzar.</p>
                    <p className="txt_web_development">Utilizamos las últimas tendencias en lenguajes de programación para que tu sitio sea actual y pueda
                    obtener todos los beneficios que ofrece Google a quienes cumplen esos parámetros.</p>
                    <p className="txt_web_development">Todos los sitios que desarrollamos son totalmente responsivos para que así el usuario pueda
                    tener la mejor experiencia independientemente del dispositivo que utilice.</p>
                    <p className="txt_web_development">Nuestros sitios son escalables, de tal manera que si con el tiempo quieres agregarle mayor funcionalidad por ejemplo un formulario, 
                    un módulo de ventas, una galería de imágenes, etc. podemos hacerlo gradualmente para que así planees tu inversión de acuerdo al crecimiento de tu negocio.</p>
                </div>            

        </div>
    );
};

const ServicioWebDevelopment = styled(ServicioWebDevelopmentBase)`    
    width: 100%;
    margin: .2rem auto;
    padding: .2rem .5rem;
    
    .info{        
        margin: 0 auto;
        padding: .4rem;        
        border-radius: .8rem;
        overflow: auto;

        .txt_web_development{        
          color: ${colors.txt_2};
          font-size: 1.1rem;
          list-style-type: none;
          text-align: justify;
          padding: .3rem;
        }
    }

    .watermark{        
        ${absolute({x:"auto", y:"20%"})};
        ${setFlex()};
        width: 95%;
        padding: .5rem;
        opacity: 0.07;

        img{
          height: 30vh;
          width: auto;
        }        
    }


    ${below.md`      

        .info{                      
          margin: 0 auto;
          padding: .5rem;
          width: 100%;

          .txt_web_development{        
            font-size: 1.1rem;
            list-style-type: none;
            padding: .2rem;
          }
        }


    `};

    ${below.sm`

        .watermark{
            margin-top: 6rem;
            margin-left: 0.5rem;
        }

        .icon{
            width: 80%;
        }

    `};

`

export default ServicioWebDevelopment;