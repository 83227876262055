import React from 'react';
import styled from 'styled-components';
import { colors, setFlex, absolute, below } from '../utilities';


const ServicioSocialMediaBase = ({className}) => {
    return (
        <div className={className}>            
                <div className="watermark">
                    <img className="icon" alt="" src="https://i.postimg.cc/gcMJ0tDW/social-media.png"/>
                </div>
                <div className="info">
                    <p className="txt_social_media">En el mundo actual no podemos dejar de prestar atención a la importancia que tienen las redes sociales en el comportamiento de los 
                    consumidores. Es por esto que te apoyamos para que cubras todas las redes sociales que pueden impactar a tu negocio y generamos a tu lado contenido relevante 
                    y atractivo para tu público objetivo.</p>
                    <p className="txt_social_media">Si ya tienes creados los perfiles de tus redes sociales, los analizamos para poder determinar si estan enfocados 
                    a los resultados que esperas obtener, o bien creamos tus perfiles desde cero siempre con el objetivo de alcanzar tus metas en el menor tiempo posible.</p>
                    <p className="txt_social_media">Es muy importante tener en mente que las redes sociales son un canal de comunicación abierto con nuestros consumidores, por lo que 
                    es vital tener una respuesta rápida y efectiva cuando se generen comentarios u opiniones negativas para de esta manera conseguir la mejor imagen posible.
                    Todo negocio tiene errores y fallas, pero la manera de responder ante ellos hace una gran diferencia en como nos percibe nuestro público.</p>
                    <p className="txt_social_media">Te ayudamos a mejorar tus tiempos de respuesta y a eficientar tu comunicación. Generamos contenido relevante frecuentemente para 
                    que tu público objetivo siempre te tenga en mente, eligiendo el formato más adecuado (foto, animación, video, texto, etc.) para conseguirlo.</p>
                    <p className="txt_social_media">Te ofrecemos estadísticas que sean útiles para tomar las mejores decisiones en cuanto al presupuesto que decidas invertir 
                    en estas redes, de esta manera tendrás información que apoye a que cada peso invertido obtenga la mejor tasa de retorno .</p>
                </div>            

        </div>
    );
};

const ServicioSocialMedia = styled(ServicioSocialMediaBase)`    
    width: 100%;
    margin: .2rem auto;
    padding: .2rem .5rem;
    
    .info{        
        margin: 0 auto;
        padding: .4rem;        
        border-radius: .8rem;
        overflow: auto;

        .txt_social_media{        
          color: ${colors.txt_2};
          font-size: 1.1rem;
          list-style-type: none;
          text-align: justify;
          padding: .3rem;
        }
    }

    .watermark{        
        ${absolute({x:"auto", y:"20%"})};
        ${setFlex()};
        width: 95%;
        padding: .5rem;
        opacity: 0.07;

        img{
          height: 30vh;
          width: auto;
        }        
    }


    ${below.md`      

        .info{                      
          margin: 0 auto;
          padding: .5rem;
          width: 100%;

          .txt_social_media{        
            font-size: 1.1rem;
            list-style-type: none;
            padding: .2rem;
          }
        }


    `};

    ${below.sm`

        .watermark{
            margin-top: 6rem;
            margin-left: 0.5rem;
        }

        .icon{
            width: 80%;
        }

    `};

`

export default ServicioSocialMedia;