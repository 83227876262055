import React from 'react';
import useSlider from '../hooks/useSlider';
import SlideAlfa from '../elements/slider/SlideAlfa';
import SlideWebDevelopment from '../elements/slider/SlideWebDevelopment';
import SlideGoogleAds from '../elements/slider/SlideGoogleAds';
import SlideSocialMedia from '../elements/slider/SlideSocialMedia';
import SlideAnalytics from '../elements/slider/SlideAnalytics';
// import SlideJumpings from '../elements/slider/SlideJumpings';
// import SlideLittle from '../elements/slider/SlideLittle';
import styled from 'styled-components';
import {below} from '../utilities';

const SliderBase = ({className}) => {
// <SlideNoryan/> , <SlideInflables/>, <SlideFut/>, <SlideRock/>, <SlideMaquinitas/>, <SlideJumpings/>, <SlideLittle/> 
//   let sliderComponents = [ <SlideNoryan/> , <SlideInflables/>, <SlideFut/>, <SlideRock/>, <SlideMaquinitas/>, <SlideJumpings/>, <SlideLittle/> ]; 

  let sliderComponents = [ <SlideAlfa/>, <SlideWebDevelopment/>, <SlideGoogleAds/>, <SlideSocialMedia/>, <SlideAnalytics/> ]; 


  const { offset, addItem } = useSlider({
    total: sliderComponents.length,
    enabled: true,
    useLoaded: false,
    speed: 6000
  });
  
  if(!sliderComponents){
    console.log(addItem);
  }
  
  return (    
    <div className={className}>
      {sliderComponents.map( (component,idx) => {
          return (
            <div key={idx} className="slide" style={{ transform:`translateX(${offset * -100}%)` }}>
              {component}
            </div>
          )
      })}          
    </div>
  );
}

const Slider = styled(SliderBase)`  
  width: 100%;  
  box-sizing: border-box;
  margin-top: 1rem;
  padding: 0;
  display: flex;
  align-items: center;  
  
  .slide{ 
    min-width: 100%;
    height: 100%;
    position: relative;
    transition: .5s;
    border-radius: .8rem;  
  }

  ${below.md`
    margin-top: 2rem;
  `}
`;


export default Slider;
