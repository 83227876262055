import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class WebDevelopment extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlspace="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g><path fill={color} d="M417.3,817.5H208.2v33.3h585.5v-33.3H589.1v-40.4H990V149.2H10v627.9h407.3V817.5z M81.7,684.6V220.9h836.6v463.7H81.7z"/><path fill={color} d="M383.4,348c-5.9,0-11.1,2.4-14.9,6.3l0,0l-82,82c-4.4,3.8-7.3,9.4-7.3,15.7c0,5.8,2.4,11.1,6.3,14.9l0,0l83.2,83.2l0,0c3.7,3.6,8.8,5.9,14.5,5.9c11.5,0,20.8-9.3,20.8-20.8c0-5.6-2.3-10.7-5.9-14.5l0,0L329.5,452l68.4-68.4l0,0c3.9-3.8,6.3-9,6.3-14.9C404.1,357.3,394.8,348,383.4,348z"/><path fill={color} d="M715.7,436.2l-82-82l0,0c-3.8-3.9-9.1-6.3-14.9-6.3c-11.5,0-20.8,9.3-20.8,20.8c0,5.8,2.4,11.1,6.3,14.9l0,0l68.4,68.4l-68.6,68.6l0,0c-3.6,3.7-5.9,8.8-5.9,14.5c0,11.5,9.3,20.8,20.8,20.8c5.6,0,10.7-2.3,14.5-5.9l0,0l83.2-83.2l0,0c3.9-3.8,6.3-9,6.3-14.9C723,445.6,720.1,440,715.7,436.2z"/><path fill={color} d="M556.4,285.6c-9,0-16.7,5.7-19.6,13.7L426.1,590c-0.9,2.3-1.4,4.8-1.4,7.5c0,11.5,9.3,20.8,20.8,20.8c9,0,16.7-5.7,19.6-13.7l110.7-290.7c0.9-2.3,1.4-4.8,1.4-7.5C577.2,294.9,567.9,285.6,556.4,285.6z"/></g>
            </svg>
        )
    }
}

WebDevelopment.propTypes = {
    color: string,
    size: number
};


WebDevelopment.defaultProps = {
    color: '#000',
    size:20
}