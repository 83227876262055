import React from 'react';
import styled from 'styled-components';
import { colors, setFlex, absolute, below } from '../utilities';


const ServicioSeoBase = ({className}) => {
    return (
        <div className={className}>            
                <div className="watermark">
                    <img className="icon" alt="" src="https://i.postimg.cc/W1qmgs06/seo.png"/>
                </div>
                <div className="info">
                    <p className="txt_seo">SEO signigica Optimización de Motores de Busqueda. Pero ¿Qué es eso? SEO hace referencia a todas aquellas prácticas necesarias 
                    para conseguir que un motor de busqueda (Google que ocupa el 99% de las mismas) determine un sitio web como relevante para el usuario y de esta manera 
                    lo "recompence" con una mejor posición dentro de los resultados orgánicos.</p>
                    <p className="txt_seo">Ahora bien ¿Qué son los resultados orgánicos? Son todas aquellas impresiones que aparecen como respuesta a las palabras de busqueda 
                    ingresadas y que no generaron un costo para el dueño de esos sitios web. En otras palabras, son los resultados que aparecen abajo de todos aquellos enlaces
                    patrocinados.</p>
                    <p className="txt_seo">Entonces ¿Por qué es importante el SEO si voy a aparecer abajo de los enlaces patrocinados? Su importancia radica en que aún 
                    apareciendo debajo de los anuncios, todavía estaremos en la primera hoja de resultados que es la única hoja que el 95% de los internautas revisa. Además de 
                    que si en un momento decidimos incursionar en los enlaces pagados (GoogleAds) una buena optimización de nuestro sitio contribuirá a un menor costo en nuestra
                    publicidad ya que seremos relevantes para los usuarios y eso Google lo reconocerá siempre.</p>
                </div>            

        </div>
    );
};

const ServicioSeo = styled(ServicioSeoBase)`    
    width: 100%;
    margin: .2rem auto;
    padding: .2rem .5rem;
    
    .info{        
        margin: 0 auto;
        padding: .4rem;        
        border-radius: .8rem;
        overflow: auto;

        .txt_seo{        
          color: ${colors.txt_2};
          font-size: 1.1rem;
          list-style-type: none;
          text-align: justify;
          padding: .3rem;
        }
    }

    .watermark{        
        ${absolute({x:"auto", y:"20%"})};
        ${setFlex()};
        width: 95%;
        padding: .5rem;
        opacity: 0.07;

        img{
          height: 30vh;
          width: auto;
        }        
    }


    ${below.md`      

        .info{                      
          margin: 0 auto;
          padding: .5rem;
          width: 100%;

          .txt_seo{        
            font-size: 1.1rem;
            list-style-type: none;
            padding: .2rem;
          }
        }


    `};

    ${below.sm`

        .watermark{
            margin-top: 6rem;
            margin-left: 0.5rem;
        }

        .icon{
            width: 80%;
        }

    `};

`

export default ServicioSeo;