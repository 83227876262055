import styled from 'styled-components';
import { colors, setFlex, demotion, below, above, elevation, absolute } from '../utilities';

export const Wrapper = styled.div`    
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(${colors.white_1}, ${colors.txt_1});    
    overflow: auto;    
`;

export const SectionsContainer = styled.div`
    width: 100%;
    height: 75vh;    
    position: relative;
    overflow: auto;   
`; 

export const Space = styled.div`    
    height: ${props => props.h || '3rem' };
    width: ${props => props.w || '100%' };    
`;

export const SliderContainer = styled.div`  
  position: relative;
  color: ${colors.white_1};
  width: 98%;
  height: 70vh;
  margin: 0 auto;
  border-radius: .7rem;
  ${elevation[4]};
  flex-flow: column nowrap;
  
  /*
  
   */
  .icon{    
    ${absolute({ x:"2rem", y:"2rem" })};
  }

  .title {    
    color: ${colors.txt_light};  
    font-size: 2.5rem;
    width: 70%;
    margin: 0 auto;    
    line-height: 3.5rem;
    text-shadow: 6px 6px 12px rgba(0,0,0,0.4),
              -6px -6px 12px rgb(210, 233, 249, .5);
  }

  .txt{
    width: 90%;
    font-size: 2rem;
    font-weight: 600;    
    margin: 0 auto;
    
  }

  ${below.md`    
    
    .txt{
      margin: 1.5em auto 0rem;
      width: 100%;
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 2rem;
    }
  `}

  ${below.sm`
    height: 65vh;
  `}
`;
