import React from 'react';
import styled from 'styled-components';
import { colors, below} from '../../utilities';
import {SectionsContainer} from '../../elements/Container';


const PreguntasBase = ({className}) => {
    return (
        <SectionsContainer className={className}>
            <div className="title">
                <h2 className="preguntas">Preguntas Frecuentes</h2>
            </div>
            <div className="questions_list">
                <div className="question_answer">
                    <p className="question">1.- ¿Cómo puedo obtener una cotización de sus servicios?</p>
                    <p className="answer">R.- Puedes escribirnos a nuestro WhatsApp para organizarnos y tener una breve plática contigo y así conocer 
                    tus inquietudes y necesidades para poder ofrecerte la mejor solución; esta plática no nos tomará más de 30 minutos y puede ser vía 
                    telefónica o por video llamada.</p>
                </div>
                <div className="question_answer">
                    <p className="question">2.- ¿No tengo ningún conociemiento sobre este tema, aún así puedo contratar sus servicios?</p>
                    <p className="answer">R.- Claro que sí, nuestro objetivo es facilitar a nuestros clientes el incursionar en el mundo del Marketing 
                    Digital, por lo que no es necesario ningún conocimiento sobre este tema, nosotros te presentamos nuestra propuesta de servicios y te 
                    la explicamos en lenguaje sencillo y claro para que puedas tomar la mejor decisión.</p>
                </div>
                <div className="question_answer">
                    <p className="question">3.- ¿Qué tiempo tardan en elaborar mis herramientas de Marketing Digital?</p>
                    <p className="answer">R.- Esto es muy variable toda vez que depende del tamaño del proyecto y la cantidad de módulos que se vayan a 
                    desarrollar, sin embargo, siempre buscamos tener las herramientas listas lo más pronto posible para que puedas empezar a ver resultados.</p>
                </div>
                <div className="question_answer">
                    <p className="question">4.- ¿Cuándo tengo que pagar por sus servicios?</p>
                    <p className="answer">R.- Nosotros solicitamos sólo un 10% de anticipo para formalizar la solicitud de servicios y el pago final se 
                    pide hasta que entregamos las herramientas listas y funcionando.</p>
                </div>
                <div className="question_answer">
                    <p className="question">5.- ¿Por qué existe tanta diferencia de costos entre las diferentes agencias de Marketing Digital?</p>
                    <p className="answer">R.- En un mercado tan competitivo y abierto existen diferentes criterios para costear los servicios que ofrecemos, 
                    nosotros siempre buscamos que cualquier negocio, independientemente de su giro o tamaño, pueda contratar nuestros servicios.</p>
                </div>
                <div className="question_answer">
                    <p className="question">6.- ¿Qué formas de pago aceptan?</p>
                    <p className="answer">R.- Aceptamos prácticamente todas las formas de pago. Puede ser efectivo, depósito, transferencia, pago físico con 
                    tarjeta de crédito, PayPal y Mercado Pago.</p>
                </div>
                <div className="question_answer">
                    <p className="question">7.- ¿Puedo obtener factura de los servicios que contrate con ustedes?</p>
                    <p className="answer">R.- Desde luego, siempre podremos emitir factura, si así se nos solicita, independientemente del medio de pago que 
                    se utilice para pagarnos.</p>
                </div>
            </div>
        </SectionsContainer>
    );
};

const Preguntas = styled(PreguntasBase)`
    width: 100%;


    .title{
        font-size: 2rem;
        text-align:center;
        font-weight: 700;
        letter-spacing: .4rem;
        text-shadow: 4px 4px 15px ${colors.txt_1};
        color: ${colors.txt_2};  
        margin-top: 1rem;
    }

    .preguntas{
        margin-top: 0;
        margin-bottom: 2.5rem;
    }

    .questions_list{
        color: ${colors.txt_2};
        font-size: 1rem;
        margin: 0 4rem;
    }

    .question_answer{
        margin-bottom: 2.5rem;
    }

    .question{
        font-weight: bold;
    }

    .answer{
        text-align: justify;
    }

    ${below.lg`

        .title{
            font-size: 1.5rem;
        }

    `};

    ${below.md`
        
        margin-top: 2.5rem;

        .title{
            font-size: 1rem;
        }

        .questions_list{
            margin: 0 1rem;
        }

    `};

`

export default Preguntas;