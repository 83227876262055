import React, {Component} from 'react';
import {number, string } from 'prop-types';

export default class Analytics extends Component {
    render(){
        const {color, size} = this.props;
        return (
            <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xmlspace="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g><path fill={color} d="M264.8,578.4h509.6V304H264.8V578.4z M402,549h-39.2v-88.2H402V549z M509.8,549h-49V421.6h49V549z M598,549h-49v-88.2h49V549z M686.2,549h-49V421.6h49V549z M489.3,343.9l93.1,59.6l118.5-85.7l13.8,19l-131.4,95.1L488.1,371l-152.7,78.4l-10.9-20.8L489.3,343.9z"/><path fill={color} d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M605.8,734.6c-7.8,1-80.8,0.1-86.2,0c-5.4,0.1-78.4,1-86.2,0c-8-1.1,0-7.5,10.2-15c10.2-7.5,11.3-18.8,11.3-18.8l3.8-24.4h121.8l3.8,24.5c0,0,1.1,11.3,11.3,18.8C605.8,727.1,613.8,733.6,605.8,734.6z M813.6,633.3c0,13-10.5,23.5-23.5,23.5h-541c-13,0-23.5-10.5-23.5-23.5v-345c0-13,10.5-23.5,23.5-23.5h541c13,0,23.5,10.5,23.5,23.5V633.3z"/></g>
            </svg>        
        )
    }
}

Analytics.propTypes = {
    color: string,
    size: number
};


Analytics.defaultProps = {
    color: '#000',
    size:20
}