import React from 'react';
import styled from 'styled-components';
import {SectionsContainer} from '../../elements/Container';
import Event from '../../elements/svg/Event.jsx';
import Many from '../../elements/svg/Many.jsx';
import Analytics from '../../elements/svg/Analytics.jsx';
import {setFlex, colors, demotion, below, elevation} from '../../utilities';

const SolucionesBase = ({className}) => {
    return (            
        
        <SectionsContainer className={className}>
        <h2 className="soluciones">Soluciones Integrales</h2>
        <div className="box_container">
            <div className="box">
                <div className="icon">
                    <Event size={50}/>
                </div>
                <p className="box_title">Todo Incluido</p>
                <p className="box_txt">Te ofrecemos una solución que incluye todo lo necesario para arrancar desde cero y así puedas tener una estrategía 
                de Marketing Digital completa. Desde crear o rediseñar tu sitio web, hasta manejar todas tus campañas publicitarias y generar contenido en 
                las mismas. También respondemos por tí los primeros contactos que lleguen a tus redes sociales incluyendo WhatsApp para redirigirlo a los
                canales correctos y tus clientes reciban siempre las mejor atención.</p>
            </div>
            <div className="box">
                <div className="icon">
                    <Many size={50}/>
                </div>
                <p className="box_title">Módulos a la Carta</p>
                <p className="box_txt">Elige sólo los módulos en que quieras que te apoyemos. Puedes irlos complementando de forma gradual 
                conforme vayas viendo resultados o bien ir intercambiando entre ellos según consideres mejor para tu negocio. Así te iremos ayudando a que inicies 
                todo lo necesario para tu estrategía de Marketing Digital y después tú lo puedas administrar.</p>
            </div>
            <div className="box">
                <div className="icon">
                  <Analytics size={50}/>
                </div>
                <p className="box_title">Manejo Constante</p>
                <p className="box_txt">Podemos trabajar contigo de forma permanente apoyándote a manejar tus campañas publicitarias tanto en Google Ads como en 
                redes sociales. Tambien generamos para tí contenido relevantes de forma diaria en tus redes sociales y podemos estar a cargo de redirigir a los canales 
                correctos todas las interacciones que tengas en mensajes incluyendo WhatsApp. Aquí estaremos siempre a tu lado para que obtengas los resultados esperados.</p>
            </div>
        </div>
        </SectionsContainer>
        
    );
};

const Soluciones = styled(SolucionesBase)`    
  color: ${colors.txt_2};
  width: 95%;
  height: 75vh;  
  margin: 0.5rem auto;
  border-radius: .7rem;
  position: relative;  
  
  .soluciones{      
    font-size: 3rem;
    text-align:center;
    font-weight: 700;
    letter-spacing: .4rem;
    text-shadow: 4px 4px 15px ${colors.txt_1};
    color: ${colors.txt_2};  
    margin: 1rem auto;
}

  .box_container{
    ${setFlex({y:"stretch"})};
    padding: 0.5rem;
    text-align: center;    
    overflow: auto;
      
      .box{
        width: 31%;
        margin: 1rem 1rem;
        border-radius: .8rem;
        padding: 1rem;
        ${elevation[4]};        
      
          .icon{
            margin: 1rem auto;
            width: 95%;              
          }

          .box_title{                
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
          }

          .box_txt{              
            font-size: 1rem;
            text-align: justify;
          }
      }
  }

    ${below.lg`           
      .box_container{
        padding: .5rem;        
        
        .box{
          width: 90%;
          margin: 0 auto;            
        }
      }     
    `};

    ${below.md`      
      ${demotion[4]};
      margin: 3rem auto;      

      .soluciones{      
        font-size: 1.5rem;      
      }

      .box_container{
        flex-flow: column nowrap;
        padding: 0rem;
        
        .box{
          width: 90%;
          margin: 1.5rem auto;          
        }

        .box_title{
            font-size: 1.2rem;
        }
      }
    `}

    
`;


export default Soluciones;