import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {setFlex, below, colors, absolute} from '../../utilities';
import {SliderContainer} from '../Container';
import Google from '../svg/Google';
import {SliderLink} from '../Button';

const GoogleAds = ({className}) => {
  return (
    <SliderContainer className={className}>    
      <div className="icon"><Google size={40}/></div>      
      <h3 className="title">Google Ads</h3>
      <div className="img_box">
        <img className="img" src="https://res.cloudinary.com/ds0rddctm/image/upload/q_auto:low,f_auto/v1605229526/AMD/Google-Ads.jpg" alt="google_ads"/>
      </div>
      
      <div className="text_box">
        <p className="txt">Anúnciate donde todos buscan</p>
        <p className="txt">Invierte en publicidad efectiva y obtén los mejores resultados</p>
      </div> 
      
      <div className="link">
        <SliderLink>
          <Link to={'/servicios/google_ads'}>Explorar</Link> 
        </SliderLink>
      </div>

    </SliderContainer>
  );
}

const SlideGoogleAds = styled(GoogleAds)`  
  position: relative;
  
  .title{
    color: ${colors.txt_2};
    width: auto;
    margin: 1rem auto;
    padding-top: 1.5rem;
    padding-left: 10%;
  }

  .img_box{
    height: 50%;
    ${setFlex({x:"flex-end"})};

    img{
      height: 35vh;
      margin-right: 2rem;
    }

    img:hover {
        filter: blur(3px);
    }
  }

  .text_box{
    ${absolute({ x:"2rem", y:"30%" })};

    .txt{
      color: ${colors.txt_2};
      margin-bottom: 1rem;
      text-shadow: 6px 6px 12px ${colors.txt_1},
              -6px -6px 12px ${colors.txt_1};
    }
  }

  ${below.lg`    
    .title{            
      padding-left: 20%;
      margin-bottom: 2rem;
    }

    .img_box{          
      ${setFlex()};
      margin-bottom: 2rem;

      img{
        height: 32vh;
        margin-right: 0rem;
      }
    }

    .text_box{
      position: static;      
      line-height: 1.5rem;
      margin-top: 1.5rem;

      .txt{
        margin-top: 1rem;
        font-size: 1.8rem;
        text-align: center;
      } 
    }
    
    .link{
      position: static;
      margin-top: 2rem;
    }
  `}

  ${below.md`

    .img_box{
        margin-bottom: 1rem;
    }

    .text_box{
        margin-top: 1rem;
    }
  `}

  ${below.sm`    
    .title{      
      padding-left: 25%;
      font-size: 1.8rem;
    }

    .img_box{
      margin-top: -1rem;

      img{
        height: 23vh;
      }
    }

    .text_box{
      position: static;
      width: 97%;
      line-height: 1.5rem;      
          
      .txt{
        margin-top: 0rem;
        font-size: 1.5rem;
      } 
    }    
  `}

${below.xsm`
    .title{           
      font-size: 1.4rem;
      padding-left: 30%; 
    }
    .img_box{
      margin-top: -3rem;

      img{
        height: 13vh;
      }
    }
    
    .text_box{
      margin-top: -2rem;
              
      .txt{
        margin-top: -1rem;
        font-size: 1.2rem;
      } 
    }   

    .link{
      margin-top: 0;
    }

  `}

`;

export default SlideGoogleAds;
