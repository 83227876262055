import React from 'react';
import styled from 'styled-components';
import {setFlex, colors, below, fixed} from '../utilities';
import { Whats, Phone } from '../elements/Icons';
import { Link } from 'react-router-dom';
import {Space} from '../elements/Container';

function TopBarBase({ className }){
    return (
      <>
      <div className={className}>
      <Link className="link" to='/'><div className="logo_top"></div></Link>
        <div className="btn_wrapper">
         <a target="blank" href="https://api.whatsapp.com/send?phone=525559099044&amp;text=Hola,%20me%20gustaría%20conocer%20más%20de%20sus%20servicios." className="contact_numbers">
           <Whats size={25}/><span>55-5909-9044</span></a>
           <a target="blank" href="tel:5568323170" className="contact_numbers"><Phone size={25}/><span>55-6832-3170</span></a>
        </div>        
      </div>
      <Space/>
      </>


    )
}

const TopBar = styled(TopBarBase)`    
    ${fixed()}
    width: 100%;
    height: 4rem;
    padding: 0;   
    background-color: ${colors.white_2};           
    ${setFlex({ x:"space-around"})};    

    .link{
      width: 70%;
    }
  
    .logo_top{      
      position: relative;
      background-image: url('https://i.postimg.cc/WbKCnX3h/Alfa-Logo-Ok.png');
      background-position: left;
      background-repeat: no-repeat;
      width: 100%;
      height: 3.5rem;
      margin-left: 1rem;
      cursor: pointer;
      display: inline-block;
    }

    .btn_wrapper{
      width: 70%;
      ${setFlex({ x:"space-around" })};
      padding: 0 1rem;

      .contact_numbers{
        color: ${colors.txt_2};
        text-decoration: none;
        font-size: 1rem;        
      }
    }
    

    ${below.md`
      .logo_top{        
        background-image: url('https://i.postimg.cc/KKPtcZbm/Alfa-Logo-Reducido.png');
        margin-left: -1rem;  
        height: 5rem;
        transform: scale(.7);
      }

      .btn_wrapper span{
        display: none;
        width: 50%;
      }
    `}

    ${below.sm`
      
      .logo_top{        
        transform: scale(.4);
      }
    `}
    
`;

export default TopBar;
