import React from 'react';
import styled from 'styled-components';
import { colors, setFlex, absolute, below } from '../utilities';


const ServicioAnalyticsBase = ({className}) => {
    return (
        <div className={className}>            
                <div className="watermark">
                    <img className="icon" alt="" src="https://i.postimg.cc/Fztg2FB9/analytics.png"/>
                </div>
                <div className="info">
                    <p className="txt_analytics">Puedes tener el mejor sitio web y estar invirtiendo mucho en GoogleAds, pero eso no te va a garantizar los resultados que 
                    esperas, recuerda que la información es poder y nosotros ponemos ese poder en tus manos para que decidas dónde, cómo, cuándo y cuánto invertir para que genere 
                    los mejores reultados y tengas la mejor tasa de retorno a la inversión que estes haciendo en GoogleAds.</p>
                    <p className="txt_analytics">El tiempo que tienes el limitado y muy valioso, te orientamos para que obtengas el mayor provecho de toda la información y 
                    las herramientas que Analytics ofrece, así tendrás a la mano datos duros y entendibles para una ágil toma de decisiones.</p>
                    <p className="txt_analytics">Las redes sociales tambien ofrecen estadísticas importantes a considerar al momento de invertir en anuncios y publicaciones
                    pagadas, generamos datos relevantes para tu negocio y te apoyamos a analizarlos para que puedas orientar tus campañas de la mejor manera.</p>
                    <p className="txt_analytics">Podemos ayudarte a generar y analizar todas las estadísticas que requieras, no sólo desde las plataformas de marketing
                    digital, sino de cualquier area de tu negocio que necesiten tener bajo la lupa. Nos proporcionas los datos y generamos los reportes que sean 
                    necesarios para tu toma de decisiones.</p>
                </div>            

        </div>
    );
};

const ServicioAnalytics = styled(ServicioAnalyticsBase)`    
    width: 100%;
    margin: .2rem auto;
    padding: .2rem .5rem;
    
    .info{        
        margin: 0 auto;
        padding: .4rem;        
        border-radius: .8rem;
        overflow: auto;

        .txt_analytics{        
          color: ${colors.txt_2};
          font-size: 1.1rem;
          list-style-type: none;
          text-align: justify;
          padding: .3rem;
        }
    }

    .watermark{        
        ${absolute({x:"auto", y:"20%"})};
        ${setFlex()};
        width: 95%;
        padding: .5rem;
        opacity: 0.07;

        img{
          height: 30vh;
          width: auto;
        }        
    }


    ${below.md`      

        .info{                      
          margin: 0 auto;
          padding: .5rem;
          width: 100%;

          .txt_analytics{        
            font-size: 1.1rem;
            list-style-type: none;
            padding: .2rem;
          }
        }


    `};

    ${below.sm`

        .watermark{
            margin-top: 6rem;
            margin-left: 0.5rem;
        }

        .icon{
            width: 80%;
        }

    `};

`

export default ServicioAnalytics;