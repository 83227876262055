import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {setFlex, below, absolute, colors} from '../../utilities';
import {SliderContainer} from '../Container';
import {SliderLink} from '../Button';

const Alfa = ({className}) => {
  return (
    <SliderContainer className={className}>     
      <h1 className="title">Tu mejor opción en Marketing Digital</h1>
      <img src="https://res.cloudinary.com/ds0rddctm/image/upload/q_auto:low,f_auto/v1605131583/AMD/Alfa_Logo_Corto.png" alt="alfa"/>     
      <div className="link">
        <p className="txt">Anímate y descubre todo lo que el mundo OnLine tiene para ti</p>
        <p className="txt_highlighted">DESDE $6,000 PAGO ÚNICO</p>
        <SliderLink>
          <Link to={'/servicios/web_development'} >Explorar</Link>  
        </SliderLink>
      </div>                     
    </SliderContainer>
  );
}

const SlideAlfa = styled(Alfa)`  
  ${setFlex({x:"space-between"})};
  width: 95%;

  .title{
    color: ${colors.txt_2};
    margin: 1rem auto;
    font-size:3rem;
    font-weight: bolder;    
    text-align: center;
    text-shadow: 6px 6px 12px ${colors.txt_1},
            -6px -6px 12px ${colors.txt_1};
    animation: moveInLeft 1.5s ease-out;
    
  } 
  
  .txt{      
      color: ${colors.txt_2};
      width: 100%;
      text-align: center;
      padding: 0.5rem 0;
      font-size:2.5rem;
      font-weight: 600;
      text-shadow: 6px 6px 12px ${colors.txt_1},
              -6px -6px 12px ${colors.txt_1};
      animation: moveInRight 1.5s ease-out;
  }

  .txt_highlighted{      
    color: ${colors.white_4};
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
    font-size:3.5rem;
    font-weight: 700;
    text-shadow: 6px 6px 12px ${colors.txt_1},
            -6px -6px 12px ${colors.txt_1};
}

  
  .link{
    margin-bottom: 0.5rem;
  }

  img{    
    ${absolute({x:"auto"})};
    height: 19vh;
    margin-top: 6rem;
    padding-bottom: 4rem;
  }

  @keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }

    80% {
      transform: translateX(10px);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }

    80% {
      transform: translateX(-10px);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }


  ${below.lg`
    img{
        ${absolute({x:"auto", y:"10%"})};
        height: 17vh;
      }

    .link{
        margin-top: 6.5rem;
    }

  `}

  ${below.md`    
    .title{
      width: 95%;
      font-size:2.5rem;
      margin-top: 1.5rem;
    }
   
    .txt{
      font-size:2rem;
    }

    .txt_highlighted{
      font-size:2.5rem;
    }

      
  `}

  ${below.sm`    
    .title{     
      width: 100%;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
   
    .txt{
      width: 80%;
      font-size:1.5rem;
    }

    .txt_highlighted{
      font-size:2rem;
    }


    img{
      ${absolute({x:"auto", y:"10%"})};
      height: 16vh;
      width: auto;
      margin-top: 4rem;
    }

    .link{
      margin-top: 3rem;
    }

  `}

  ${below.xsm`
    .title{     
      width: 100%;
      font-size:1.7rem;
      margin-top: 1.5rem;
      line-height: 2.2rem;
    }
   
    img{
      ${absolute({x:"auto", y:"10%"})};
      height: 15vh;
      width: auto;
      margin-top: 5rem;
    }
    
  `}
`;

export default SlideAlfa;
