import React from 'react';
import styled from 'styled-components';
import {setFlex, colors, elevation, demotion, below, fixed} from '../utilities';
import { NavLink, useRouteMatch } from 'react-router-dom';
import WebDevelopment from '../elements/svg/WebDevelopment';
import Google from '../elements/svg/Google';
import Face from '../elements/svg/Face';
import Analytics from '../elements/svg/Analytics';
import Seo from '../elements/svg/Seo';

const ServicesMenuBase = ({ className }) => {
    
    let {url} = useRouteMatch();
    
    return (
        <div className={className}>
            <div className="table">
                <ul className="nav_list">
                    <li className="nav_service">
                        <NavLink 
                            className="nav_link" 
                            activeClassName="active_link"             
                            exact to={`${url}/web_development`}>
                            <WebDevelopment className="icon" size={25}/>
                            <p className="text">Web Development</p>
                        </NavLink>
                    </li>
                    <li className="nav_service">
                        <NavLink
                            className="nav_link" 
                            activeClassName="active_link"
                            exact to={`${url}/google_ads`}>
                            <Google className="icon" size={25}/>
                            <p className="text">Google Ads</p>
                        </NavLink>
                    </li>
                    <li className="nav_service">
                        <NavLink
                            className="nav_link" 
                            activeClassName="active_link"             
                            exact to={`${url}/social_media`}>        
                            <Face className="icon" size={25}/>
                            <p className="text">Social Media</p>
                        </NavLink>
                    </li>
                    <li className="nav_service">
                        <NavLink
                            className="nav_link" 
                            activeClassName="active_link"             
                            exact to={`${url}/analytics`}>        
                            <Analytics className="icon" size={25}/>
                            <p className="text">Analytics</p>
                        </NavLink>
                    </li>
                    <li className="nav_service">
                        <NavLink
                            className="nav_link" 
                            activeClassName="active_link"             
                            exact to={`${url}/posicionamiento_seo`}>        
                            <Seo className="icon" size={25}/>
                            <p className="text">SEO</p>
                        </NavLink>
                    </li>

                </ul>
            </div>
        </div>
    )
}
   
const ServicesMenu = styled(ServicesMenuBase)`
    position: relative;
    display: flex;
    width: 75%;
    margin: auto;
    ${demotion[3]};
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    .table{
        margin: auto;
        width: 95%;
    }


    
    .nav_list{
        list-style: none;
        margin: 1rem auto;
        display: flex;
        flex-flow: row nowrap;
        padding: 0;
                
        .nav_service{
            height: 2rem;  
            width: 19%;      
            display: block;   
            ${setFlex({ x:"flex-start", y:"space-around"})};
            position: relative;
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;
            ${elevation[5]};
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            margin: 0 0.5rem;
                                
            &:hover{
                background-color: ${colors.white_trans};
                ${elevation[3]};
            }
            
            .nav_link{
                position: relative;
                width: 100%;
                padding: 1rem;                
                color: ${colors.txt_2};
                text-decoration: none;
                cursor: pointer;
                ${setFlex()};
            }
            
            .text{
                padding-top: 1.5rem;          
                width: 100%;
                display: inline-block;
                font-size: 0.9rem;
                font-weight: 700;
                padding-bottom: 1.5rem;
                margin-left: .5rem;
                ${setFlex()};
            }        
        }
    }
  
    .active_link{
        position: relative;
        height: 2rem;        
        display: block;
        cursor: pointer;
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        background-color: ${colors.white_3};
        ${elevation[3]};
    }

    ${below.lg`
        width: 90%;

        .nav_service{
            transform: scale(.9); 
        }

    `};

    ${below.md`
        width: 95%;

        .table{
            margin: auto 0.5rem;
            width: 95%;
        }

        .nav_list{
            margin: 0.3rem auto;
        }

        .nav_service{
            transform: scale(.8); 
        }

    `};

    ${below.sm`
        overflow: auto;  

        .nav_service .nav_link .text{
            display: none;
        }
    
    `};
  
`;

export default ServicesMenu;
