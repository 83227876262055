import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Nosotros  from '../sections/Nosotros';
import Servicios  from '../sections/Servicios';
import Soluciones from '../sections/Soluciones';
import Preguntas  from '../sections/Preguntas';
import Contacto  from '../sections/Contacto';
import {SectionsContainer} from '../../elements/Container';

const Routes = () => {  
  return (
    <SectionsContainer>
      <Switch>
        <Route path='/nosotros' component={Nosotros} />
        <Route path='/servicios' component={Servicios} />        
        <Route path='/soluciones' component={Soluciones}/>
        <Route path='/faqs' component={Preguntas} />
        <Route path='/contacto' component={Contacto} />
      </Switch>
    </SectionsContainer>
  );
}

export default Routes;