import React from 'react';
import styled from 'styled-components';
import {setFlex, colors, below} from '../../utilities';
import Many from '../../elements/svg/Many';
import Event from '../../elements/svg/Event';
import PriceEvent from '../../elements/svg/PriceEvent';
import {SectionsContainer} from '../../elements/Container';

const NosotrosBase = ({className}) => {
    return (
        <SectionsContainer className={className}>
            <div className="texto_principal">
                <h2 className="nosotros">Conoce un Poco de Alfa</h2>
                <p className="txt_nosotros">Somos un grupo de especialistas en Mercadotecnia OnLine con más de 10 años de experiencia. Utilizamos
                las tecnologías más vanguardistas para obtener los mejores resultados al menor costo posible. Entendemos que la mejor manera de crecer
                es contribuyendo a que nuestros clientes crezcan primero.</p>
                <p className="txt_dos_nosotros">Ofrecemos soluciones integrales adaptables a cualquier tamaño de negocio y presupuesto. Puedes iniciar
                en el mundo OnLine sin arriesgar tu estabilidad financiera ya que con nosotros no harás un gasto sino que estarás invirtiendo en tu futuro.</p>
                <p className="txt_dos_nosotros">Nuestros proyectos son "escalables" para que crezcan a la par de tu negocio. Te mantenemos informado con reportes
                analíticos reales, útiles y entendibles. Nos mantenemos siempre a tu lado para apoyarte a tomar las mejores decisiones.</p>
            </div>
            <div className="info_adicional">
                <div className="actualizacion">
                    <Many className="icon" size={50}/>
                    <h3 className="title">Actualización Constante</h3>
                    <p className="txt_info_adicional">Nos mantenemos al día para ofrecer soluciones vanguardistas.</p>
                </div>
                <div className="experiencia">
                    <Event className="icon" size={50}/>
                    <h3 className="title">10 años de experiencia</h3>
                    <p className="txt_info_adicional">Con una gran tasa de satisfacción que nos respalda.</p>
                </div>
                <div className="soluciones">
                    <PriceEvent className="icon" size={50}/>
                    <h3 className="title">Soluciones Integrales</h3>
                    <p className="txt_info_adicional">Adaptables a un pequeño negocio familiar o a una gran empresa.</p>
                </div>
            </div>
        </SectionsContainer>
    );
};

const Nosotros = styled(NosotrosBase)`
    width: 100%;
    

    .texto_principal{
        position: relative;
        color: ${colors.txt_2};  
    }

    .nosotros{
        font-size: 3rem;
        text-align:center;
        font-weight: 700;
        letter-spacing: .4rem;
        text-shadow: 4px 4px 15px ${colors.txt_1};
        ${setFlex({ x:"space-around" })};
    }

    .txt_nosotros{
        ${setFlex({ x:"space-around" })};
        font-size: 1.1rem;
        letter-spacing: .1rem;
        text-align:center;
        line-height: 1.6rem;
        margin: auto 4rem;
    }

    .txt_dos_nosotros{
        ${setFlex({ x:"space-around" })};
        font-size: 1.1rem;
        letter-spacing: .1rem;
        text-align:center;
        line-height: 1.6rem;
        margin: 1rem 4rem 0rem 4rem;
    }

    .info_adicional{
        margin: 5rem 0rem 0rem 1rem;
    }

    .icon{
        display: block;
        margin: 0 auto;
    }

    .title{
        color: ${colors.txt_2};
        font-size: 1.2rem;
        letter-spacing: .1rem;
        font-weight: 500;
    }

    .txt_info_adicional{
        color: ${colors.txt_2};
        font-size: .9rem;
        letter-spacing: .1rem;
        margin: auto 6rem;
        line-height: 1.4rem;
    }

    .actualizacion{
        width: 33%;
        display: inline-block;
        text-align: center;
    }

    .experiencia{
        width: 32%;
        display: inline-block;
        text-align: center;
    }

    .soluciones{
        width: 33%;
        display: inline-block;
        text-align: center;
    }

    ${below.xlg`
        .info_adicional{
            margin: 4rem 0rem 0rem 0rem;
        }
    `};

    ${below.lg`
        .nosotros{
            font-size: 2rem;
        };
        
        .txt_nosotros{
            font-size: 1rem;
        }
        
        .txt_dos_nosotros{
            font-size: 1rem;
        }
        
        .info_adicional{
            display: flex;
        }

        .txt_info_adicional{
            letter-spacing: 0rem;
            margin: auto 5rem;
            line-height: 1.1rem;
        }

    `};

    ${below.md`
        margin-top: 2.5rem;

        .nosotros{
            font-size: 1.5rem;
        };

        .txt_nosotros{
            line-height: 1.2rem;
            margin: auto 1rem;

        }
        
        .txt_dos_nosotros{
            line-height: 1.2rem;
            margin: 1rem 1rem 0rem 1rem;
        }

        .info_adicional{
            margin: 0rem 0rem;
            transform: scale(.9);
            display: flex;
            flex-direction: column;
        }

        .actualizacion{
            width: 100%;
            margin-bottom: 1rem;
        }

        .experiencia{
            width: 100%;
            margin: 1rem auto;

        }

        .soluciones{
            width: 100%;
            margin: 1rem auto;
        }

        .txt_info_adicional{
            letter-spacing: 0rem;
            margin: auto 4rem;
            line-height: 1rem;
        }

    `};

    ${below.xsm`
        
        .nosotros{
            font-size: 1.2rem;
        }

        .info_adicional{
            margin: -2rem 0rem 0rem 0rem;
            display: flex;
            flex-direction: column;
        }

        .txt_info_adicional{
            letter-spacing: 0rem;
            margin: auto 4rem;
            line-height: 1rem;
        }

    `};
`;


export default Nosotros;